<template>
    <div>
        <div class="crumbs">
            <Breadcrumb v-bind:menuList="menuList"></Breadcrumb>
        </div>

        <div class="container">
            <el-form :inline="true" class="demo-form-inline">
                <el-form-item label="菜单名字:">
                    <el-input class="s-input" v-model="queryParams.name" placeholder="请输入菜单名字" clearable></el-input>
                </el-form-item>
                <el-form-item label="状态:">
                    <el-select v-model="queryParams.state" placeholder="请选择状态" clearable @change="changeQuery">
                        <el-option label="上线" :value="2"></el-option>
                        <el-option label="下线" :value="1"></el-option>
                    </el-select>
                </el-form-item>
                <div style="float: right">
                    <el-button type="primary" @click="changeQuery()">搜索</el-button>
                </div>
            </el-form>
            <div style="float: left">
                <el-button type="primary" @click="add()">新增</el-button>
            </div>
            <!-- 表格内容 -->
            <el-table v-loading="loading" element-loading-text="数据加载中" :cell-style="cellstyle" stripe border :data="pageInfo.list" class="el-table" style="margin-top: 50px;">
                <el-table-column prop="id" label="id" width="100" align="center"></el-table-column>
                <el-table-column label="菜单名字" prop="name" align="center"></el-table-column>
                <el-table-column label="菜单图标" prop="icon" align="center">
                    <!-- <template slot-scope="scope">
                        <el-image style="height:50px;width:50px;" fit="cover" class="table-td-thumb"
                            :src="scope.row.icon" :preview-src-list="[scope.row.icon]"></el-image>
                    </template>-->
                </el-table-column>
                <el-table-column label="菜单路径" prop="url" align="center"></el-table-column>
                <el-table-column label="菜单等级" prop="pid" align="center">
                    <template slot-scope="scope">
                        <span v-if="scope.row.pid == 0">一级菜单</span>
                        <span type="primary" v-else>二级菜单</span>
                    </template>
                </el-table-column>
                <el-table-column label="是否常用" prop="is_common" align="center" width="120">
                    <template slot-scope="scope">
                        <el-tag type="primary" v-if="scope.row.is_common === 2">是</el-tag>
                        <el-tag type="info" v-if="scope.row.is_common === 1">否</el-tag>
                    </template>
                </el-table-column>
                <el-table-column label="状态" prop="state" align="center" width="80">
                    <template slot-scope="scope">
                        <el-tag type="primary" v-if="scope.row.state === 2">上线</el-tag>
                        <el-tag type="info" v-if="scope.row.state === 1">下线</el-tag>
                    </template>
                </el-table-column>
                <el-table-column label="操作" prop="is_common" align="center" width="170" fixed="right">
                    <template slot-scope="scope">
                        <el-button type="text" icon="el-icon-edit" @click="edit(scope.$index, scope.row)">编辑</el-button>
                        <el-button v-if="scope.row.is_common === 2" type="text" class="red" @click="state(scope.row, 1)">取消常用</el-button>
                        <el-button v-if="scope.row.is_common === 1" type="text" class="red" @click="state(scope.row, 2)">添加常用</el-button>
                    </template>
                </el-table-column>
            </el-table>
            <!-- 上线、下线提示框 -->
            <el-dialog title="提示" :visible.sync="confirmVisible" width="300px" center>
                <div class="del-dialog-cnt">{{ confirmContent }}</div>
                <!-- <el-form :inline="true" class="demo-form-inline" v-if="is_common == 2">

                </el-form>-->
                <span slot="footer" class="dialog-footer">
                    <el-button @click="confirmVisible = false">取 消</el-button>
                    <el-button type="primary" @click="stateData">确 定</el-button>
                </span>
            </el-dialog>
            <Pagination v-bind:pageInfo="pageInfo" v-on:refreshPageSize="refreshPageSize" v-on:refreshPageNum="refreshPageNum" />
        </div>
        <!-- 新增 编辑 -->
        <el-dialog :title="title" :visible.sync="addVisible" width="600px" v-if="addVisible" class="dialog_box">
            <el-form ref="form" :model="form" label-width="90px" :rules="rules" label-position="left">
                <el-form-item label="菜单名称" prop="name">
                    <el-input v-model="form.name" placeholder="请输入菜单名称"></el-input>
                </el-form-item>
                <el-form-item label="菜单路径" prop="url">
                    <el-input v-model="form.url" placeholder="请输入菜单路径"></el-input>
                </el-form-item>
                <el-form-item label="菜单图标" prop="icon">
                    <el-input v-model="form.icon" placeholder="请输入菜单图标"></el-input>
                </el-form-item>
                <el-form-item label="菜单等级" prop="pid">
                    <el-radio-group v-model="pid">
                        <el-radio :label="0">一级菜单</el-radio>
                        <el-radio :label="1">二级菜单</el-radio>
                    </el-radio-group>
                </el-form-item>
                <el-form-item label="一级菜单" prop="pid" v-if="pid !== 0">
                    <el-select v-model="form.pid" placeholder="请选择一级菜单" class="handle-select mr10">
                        <el-option v-for="items in pidList" :key="items.index" :label="items.name" :value="items.id">
                        </el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="状态" prop="state">
                    <el-radio-group v-model="form.state">
                        <el-radio :label="2">上线</el-radio>
                        <el-radio :label="1">下线</el-radio>
                    </el-radio-group>
                </el-form-item>
                <el-form-item label="是否常用" prop="is_common">
                    <el-radio-group v-model="form.is_common">
                        <el-radio :label="2">是</el-radio>
                        <el-radio :label="1">否</el-radio>
                    </el-radio-group>
                </el-form-item>
                <el-form-item label="是否显示" prop="is_common">
                    <el-radio-group v-model="form.is_show">
                        <el-radio :label="2">是</el-radio>
                        <el-radio :label="0">否</el-radio>
                    </el-radio-group>
                </el-form-item>
                <!-- 图片上传 -->
                <el-form-item label="常用图片" prop="image" v-if="form.is_common==2">
                    <el-input v-model="form.image" placeholder="请输入图片链接"></el-input>
                    <el-button @click.prevent="choiceImg">选择图片</el-button>
                    <el-upload class="avatar-uploader up" :action="IMAGE_UPLOAD_PRO_URL" :show-file-list="false" :on-success="handleAvatarSuccess" :before-upload="beforeAvatarUpload" name="file" :headers="header">
                        <img v-if="form.image" :src="form.image" class="avatar" />
                        <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                    </el-upload>
                </el-form-item>
            </el-form>
            <span slot="footer" class="dialog-footer">
                <el-button @click="addVisible = false">取 消</el-button>
                <el-button type="primary" @click="saveData">确 定</el-button>
            </span>
        </el-dialog>
        <!-- 选择图片对话框 -->
        <div class="img">
            <Picture :ImgVisible="ImgVisible" @toggleImg="toggleImg" @getImg="getImg"></Picture>
        </div>
    </div>
</template>

<script>
import Breadcrumb from '@/components/Breadcrumb';
import Pagination from '@/components/Pagination';
import loadEvents from '@/utils/loading'
import Picture from '@/components/Picture';
import { request } from '@/common/request';
import { deal } from '@/common/main';
import { IMAGE_UPLOAD_PRO_URL } from '@/common/const'

let loads = new loadEvents();
export default {
    name: 'StoreInfo',
    components: {
        Breadcrumb,
        Pagination,
        Picture
    },
    data() {
        return {
            ImgVisible: false,
            header: {},
            pidList: [],
            platformList: [],
            positionList: [],
            pickerOptions: {
                // disabledDate(time) {
                //     return time.getTime() < Date.now() - 8.64e7;
                // }
            },
            pid: 0,
            IMAGE_UPLOAD_PRO_URL,
            rules: {
                name: [{
                    required: true,
                    message: '菜单名称不能为空',
                    trigger: 'blur'
                }],
                url: [{
                    required: true,
                    message: '菜单路径不能为空',
                    trigger: 'blur'
                }],
                icon: [{
                    required: true,
                    message: '菜单图标不能为空',
                    trigger: ['blur', 'change']

                }],
                state: [{
                    required: true,
                    message: '状态不能为空',
                    trigger: 'blur'
                }],
                is_common: [{
                    required: true,
                    message: '是否常用不能为空',
                    trigger: 'blur'
                }],
                pid: [{
                    required: true,
                    message: '菜单等级不能为空',
                    trigger: 'blur'
                }],

                is_common: [{
                    required: true,
                    message: '是否常用不能为空',
                    trigger: 'blur'
                }],
            },
            menuList: ['设置', '菜单配置'],
            loading: false,
            confirmVisible: false,
            confirmContent: '',
            queryParams: {
                name: '',
                page: 1,
                pagesize: 10,
                // platform: 'admin'
            },
            pageInfo: {},
            addVisible: false,
            title: '添加热词',
            form: {
            },
            mode: 'add',
            title: '新增菜单'
        };
    },
    created() {
        this.header.token = localStorage.getItem('token');
        this.header.platform = 'admin';
        this.loadData();
        this.loadPidData();
    },
    methods: {
        toggleImg(val) { // 关闭 img对话框
            this.ImgVisible = val;
        },
        choiceImg() {
            this.ImgVisible = true
        },
        getImg(url) {// 获取选择的img
            this.$set(this.form, 'image', url);
        },
        //状态
        state(row, is_common) {
            if (is_common === 2) {
                this.confirmContent = '确定添加常用'
            } else {
                this.confirmContent = '确定取消常用';
                row.image = ''
            }
            this.form = Object.assign({}, row);
            this.form.is_common = is_common;
            this.confirmVisible = true;
        },
        // 图片上传
        handleAvatarSuccess(res, file) {
            if (res.code == 1) {
                loads.close()
                this.$set(this.form, 'image', res.data.uri);
            }
        },
        beforeAvatarUpload(file) {
            loads.open('.avatar-uploader .el-upload', '上传中')
            const _this = this
            return deal.imageFormat(file, _this, 300)
        },
        cellstyle() {
            return 'text-align: center';
        },
        loadPidData() {
            request.get('/menu/list', { pid: 0 }).then(ret => {
                if (ret.code == 1) {
                    this.loading = false;
                    this.pidList = ret.data.list
                } else {
                    this.$message.error(ret.msg);
                }
            });
        },
        loadData() {
            this.loading = true;
            let _this = this
            request.get('/menu/list', this.queryParams).then(ret => {
                if (ret.code == 1) {
                    this.loading = false;
                    this.pageInfo = ret.data;
                } else {
                    this.$message.error(ret.msg);
                }
            });
        },
        //新增菜单
        add() {
            this.mode = 'add';
            this.title = '新增菜单配置';
            this.form = {
                state: 2,
                is_common: 1,
            };
            this.pid = 0
            this.addVisible = true;
            if (this.$refs.form) {
                this.$refs.form.clearValidate()
            }
        },
        //编辑
        edit(index, row) {
            this.form = {}
            this.mode = 'edit';
            this.title = '编辑菜单配置';
            this.current = row.id;
            const item = this.pageInfo.list[index];
            console.log(6666, item)
            let id = item.pid
            if (item.pid !== 0) {
                this.pid = 1
            }
            this.form = Object.assign({}, item);
            console.log(33333, this.form)
            this.addVisible = true;

        },
        //删除
        delete(index, row) {

        },
        //增加编辑
        saveData() {
            if (this.pid === 0) {
                this.form.pid = 0
            }
            if (this.$refs.form) {
                this.$refs.form.validate(valid => {
                    if (valid) {
                        let param = Object.assign({}, this.form);
                        var url = this.mode == 'add' ? '/menu/add' : '/menu/edit';
                        request.post(url, param).then(ret => {
                            if (ret.code == 1) {
                                this.addVisible = false;
                                this.loadData();
                                this.$message.success((this.mode == 'add' ? '新增' : '编辑') +
                                    '成功');
                                this.$refs.form.clearValidate()
                                this.imageUrl = '';
                            } else {
                                this.$message.error(ret.msg);
                            }
                        });
                    } else {
                        this.$message.error('请补全信息');

                        return false;
                    }
                });
            };
        },
        // 确定是否常用
        stateData() {
            console.log(666, this.form)
            request.post('/menu/edit', this.form).then(ret => {
                console.log(333333, this.form)
                if (ret.code == 1) {
                    this.$message.success('操作成功');
                    this.loadData();
                    this.confirmVisible = false;
                } else {
                    this.$message.error(ret.msg);
                }
            });
        },
        // 分页
        changeQuery() {
            this.queryParams.page = 1;
            this.loadData();
        },
        refreshPageSize(val) {
            this.queryParams.pagesize = val;
            this.loadData();
        },
        refreshPageNum(val) {
            this.queryParams.page = val;
            this.loadData();
        }
    }
};
</script>
<style scoped>
.red {
    color: #ff0000;
}

.green {
    color: green;
}

/* 对话框样式 */

.dialog_box >>> .el-dialog__body {
    padding: 20px 30px 20px 60px;
}

.dialog_box >>> .el-dialog__header {
    padding: 30px 30px 10px;
}

.dialog_box .el-form-item--small.el-form-item {
    margin-bottom: 20px;
}
.dialog_box .avatar-uploader-icon {
    font-size: 28px;
    color: #8c939d;
    width: 150px;
    height: 200px;
    line-height: 200px;
    text-align: center;
}

.dialog_box .avatar {
    font-size: 28px;
    width: 150px;
    height: 200px;
    line-height: 200px;
}

.dialog_box .up {
    margin-top: 10px;
    margin-bottom: -10px;
}

.dialog_box .el-button {
    margin-left: 20px;
}
</style>
